import { createContext, useEffect, useState } from "react";
import { templates } from "@/constants/template/template";
import { getWishlist } from "@/services/wishlist/wishlist";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [selectedTemplate, setSelectedTemplate] = useState({
    id: templates[1].id,
    url: templates[1].url,
    color: templates[1].theme?.bgColor,
    templateId: templates[1].templateId,
  });

  const [wishlistCount, setWishlistCount] = useState(0);

  const fetchWishlistCount = async () => {
    try {
      const response = await getWishlist();      
      setWishlistCount(response.length);
    } catch (error) {
      console.error("Error fetching wishlist:", error);
    }
  };

  useEffect(() => {
    fetchWishlistCount();
  }, []);
  // not using selectedTemplate and setSelectedTemplate in any component
  const context = {
    selectedTemplate,
    setSelectedTemplate,
    wishlistCount,
    setWishlistCount,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
